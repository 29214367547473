<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="bgc">
        <ul class="auth-cate">
          <li :class="{selected:item.ResultID==selectedItem.ResultID}" @click="selectedItemOn(item)" v-for="item in idArr" :key="item.ResultID">{{item.label}} </li>

        </ul>
        <div class="auth-item">
          <ul>
            <li>
              <div class="label">{{selectedItem.label}}</div>
              <div class="ol">
                <span>赔率：{{selectedItem.Odds}}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="auth-number">
          <ul class="num-ul">
            <li v-for="item in rowsData" :key="item.title">
              <div class="lab-num">
                <div class="label">{{item.title}}</div>
                <div class="number">
                  <div class="btns" :class="{selected:ele.selected}" @click="setCurrentItem(ele)" v-for="ele in item.arr" :key="ele.id">{{ele.label}}</div>
                </div>
              </div>
            </li>
          </ul>

        </div>

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      selectedArr: [],
      selectedItem: {},
      idArr: [],
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        {
          title: "前五和",
          arr: [
            { label: "和值大", selected: false, sameId: 1, id: 1 },
            { label: "和值小", selected: false, sameId: 1, id: 2 },
            { label: "和值单", selected: false, sameId: 2, id: 3 },
            { label: "和值双", selected: false, sameId: 2, id: 4 },
            { label: "和值尾大", selected: false, sameId: 3, id: 5 },
            { label: "和值尾小", selected: false, sameId: 3, id: 6 },
            { label: "龙", selected: false, sameId: 4, id: 7 },
            { label: "虎", selected: false, sameId: 4, id: 8 },
          ],
        },
        {
          title: "平码一",
          arr: [
            { label: "大", selected: false, sameId: 5, id: 9 },
            { label: "小", selected: false, sameId: 5, id: 10 },
            { label: "单", selected: false, sameId: 6, id: 11 },
            { label: "双", selected: false, sameId: 6, id: 12 },
            { label: "尾大", selected: false, sameId: 7, id: 13 },
            { label: "尾小", selected: false, sameId: 7, id: 14 },
            { label: "和单", selected: false, sameId: 8, id: 15 },
            { label: "和双", selected: false, sameId: 8, id: 16 },
          ],
        },
        {
          title: "平码二",
          arr: [
            { label: "大", selected: false, sameId: 9, id: 17 },
            { label: "小", selected: false, sameId: 9, id: 18 },
            { label: "单", selected: false, sameId: 10, id: 19 },
            { label: "双", selected: false, sameId: 10, id: 20 },
            { label: "尾大", selected: false, sameId: 11, id: 21 },
            { label: "尾小", selected: false, sameId: 11, id: 22 },
            { label: "和单", selected: false, sameId: 12, id: 23 },
            { label: "和双", selected: false, sameId: 12, id: 24 },
          ],
        },
        {
          title: "平码三",
          arr: [
            { label: "大", selected: false, sameId: 13, id: 25 },
            { label: "小", selected: false, sameId: 13, id: 26 },
            { label: "单", selected: false, sameId: 14, id: 27 },
            { label: "双", selected: false, sameId: 14, id: 28 },
            { label: "尾大", selected: false, sameId: 15, id: 29 },
            { label: "尾小", selected: false, sameId: 15, id: 30 },
            { label: "和单", selected: false, sameId: 16, id: 31 },
            { label: "和双", selected: false, sameId: 16, id: 32 },
          ],
        },
        {
          title: "平码四",
          arr: [
            { label: "大", selected: false, sameId: 17, id: 33 },
            { label: "小", selected: false, sameId: 17, id: 34 },
            { label: "单", selected: false, sameId: 18, id: 35 },
            { label: "双", selected: false, sameId: 18, id: 36 },
            { label: "尾大", selected: false, sameId: 19, id: 37 },
            { label: "尾小", selected: false, sameId: 19, id: 38 },
            { label: "和单", selected: false, sameId: 20, id: 39 },
            { label: "和双", selected: false, sameId: 20, id: 40 },
          ],
        },
        {
          title: "特码",
          arr: [
            { label: "大", selected: false, sameId: 21, id: 41 },
            { label: "小", selected: false, sameId: 21, id: 42 },
            { label: "单", selected: false, sameId: 22, id: 43 },
            { label: "双", selected: false, sameId: 22, id: 44 },
            { label: "尾大", selected: false, sameId: 23, id: 45 },
            { label: "尾小", selected: false, sameId: 23, id: 46 },
            { label: "和单", selected: false, sameId: 24, id: 47 },
            { label: "和双", selected: false, sameId: 24, id: 48 },
          ],
        },
      ],
      labelArr1: [
        "和值大",
        "和值单",
        "和值尾大",
        "龙",
        "和值小",
        "和值双",
        "和值尾小",
        "虎",
      ],
      labelArr2: ["大", "小", "单", "双", "尾大", "尾小", "和单", "和双"],
    };
  },

  components: {
    topTimer,
    yushe,
  },
  watch: {
    amount(val) {
      this.watchData();
    },
    // 判断是否可以点击确定提交按钮
    selectedArr: {
      deep: true,
      handler(val) {
        this.watchData();
      },
    },
    // j监听数据
    oddData: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let labelArr = ["二连赢", "三连赢", "四连赢", "五连赢", "六连赢"];
        //idArr
        let arr1 = this.filterData(resAArr, 5432, 5436, labelArr);
        arr1.forEach((item, index) => {
          item.len = index + 2;
        });
        this.idArr = arr1;
        this.idArr[0].selected = true;
        this.selectedItem = Object.assign({}, this.idArr[0]);
        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    watchData() {
      if (this.selectedArr.length == this.selectedItem.len) {
        let numArr = [];
        this.selectedArr.forEach((element) => {
          numArr.push(element.label);
        });

        let obj = {
          id: this.selectedItem.ResultID,
          Odds: this.selectedItem.Odds,
          num: numArr.join(","),
          title: this.selectedItem.label,
          label: numArr.join(","),
          money: this.amount,
        };
        this.$store.commit("setXZList", [obj]);
      } else {
        this.$store.commit("setXZList", []);
      }
    },
    setCurrentItem(ele) {
      if (ele.selected) {
        let index = this.selectedArr.findIndex(
          (element) => element.id == ele.id
        );
        this.selectedArr.splice(index, 1);
        ele.selected = false;
        this.$forceUpdate();
        return;
      }
      if (this.selectedItem.len == this.selectedArr.length) {
        this.$confirm(
          `只能选择项不能超过${this.selectedItem.len}个！`,
          "提示",
          {
            confirmButtonText: "确定",
            type: "success",
            showCancelButton: false,
          }
        );
        return;
      }
      let item = this.selectedArr.find((item) => item.sameId == ele.sameId);
      if (!item) {
        let obj = Object.assign({}, ele);
        this.selectedArr.push(obj);
        ele.selected = true;
      } else {
        this.$confirm("选择类型不合格", "提示", {
          confirmButtonText: "确定",
          type: "success",
          showCancelButton: false,
        });
      }
    },
    selectedItemOn(item) {
      if (item.ResultID != this.selectedItem.ResultID) {
        this.selectedItem = Object.assign({}, item);
        this.selectedArr = [];
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.selected = false;
          });
        });
        this.$forceUpdate();
      }
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.auth-number .num-ul .btns {
  margin-right: 15px;
  border-radius: 8px;
  width: 72px;
  font-size: 15px;
}
.auth-number .num-ul .btns {
  height: 38px;
  /* width: 38px; */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fefefe),
    to(#d8d8d8)
  );
  background: linear-gradient(180deg, #fefefe, #d8d8d8);
  -webkit-box-shadow: 0 0 3px #333;
  box-shadow: 0 0 3px #333;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.auth-number .num-ul li .number {
  width: calc(100% - 130px);
  display: flex;
}
.auth-number .num-ul li .lab-num .label {
  margin-right: 30px;
  height: 28px;
  width: 90px;
  line-height: 28px;
  color: #fff;
  border-radius: 5px;
  font-style: italic;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.auth-number .num-ul li .lab-num {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.auth-number .num-ul {
  /* padding-top: 10px; */
  padding-bottom: 20px;
}
.auth-number .num-ul li {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.auth-item li .ol span:first-child {
  margin-left: 0;
}

.auth-item li .ol span {
  position: relative;
  margin-left: 16px;
  height: 30px;
  cursor: pointer;
}
.auth-item li .ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.auth-item li .label {
  position: absolute;
  left: 0;
  top: 0;
}
.auth-item li {
  position: relative;
  height: 30px;
  padding-left: 100px;
  line-height: 30px;
}
.auth-item {
  margin-top: 10px;
  padding: 8px 10px;
  background: url("../../assets/bgc_pl.png");
  border-bottom: 1px solid #ddd;
}
.auth-cate li {
  margin-left: 10px;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  background: #ededed;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.auth-cate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
  height: 30px;
}
.flex-box {
  display: flex;
}
.selected3 {
  position: relative;
  left: 100px;
  /* height: 26px; */
  display: flex;
  justify-content: center;
}
.listmain .data.w20 {
  width: 20%;
}
.listmain .data.w20 ul {
  display: block;
}
.listmain .data.w20 ul li {
  width: 100%;
}
.listmain > .data > ul.custom3 li:last-of-type .td_name {
  width: 100px;
}
.listmain > .data > ul.custom3 li:last-of-type {
  width: 100%;
}
.listmain > .data > ul.custom3 li {
  width: 25%;
}
.listmain > .data > ul.custom25 li {
  width: 25%;
}
.listmain > .data > ul.custom_ul1 li {
  height: 26px;
  max-height: 26px;
}
.listmain > .data > ul.custom_ul1 {
  width: 100%;
  height: 52px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
  /* align-items: flex-start; */
}
.listmain > .data ul.custom_ul li {
  width: 25%;
  height: 30px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
  height: 210px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data ul li {
  width: 25%;
}

.listmain div.c2003 ul li {
  width: 50%;
}
.listmain > .data > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.listmain > .data > h3 {
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
}
.listmain div.c2003 {
  width: 100%;
}
.listmain .data {
  width: 100%;
}
.listmain > .data {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
  clear: both;
}
</style>
